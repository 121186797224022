import React, { Component } from "react";
import "./App.css";
import Repos from "./components/repos.jsx";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <header>
          <div className="App-header">Pete's Github Repos</div>
        </header>
        <main>
          <div className="App-content">
            <Repos />
          </div>
        </main>
        <footer>
          <div className="App-footer">
            <a
              href="https://www.linkedin.com/in/petterikaikkonen/"
              className="App-link"
            >
              My Linkedin Profile
            </a>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default App;
