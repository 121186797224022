import React, { Component } from "react";
import Repo from "./repo";

class Repos extends Component {
  constructor() {
    super();
    this.state = [];
  }

  componentDidMount() {
    fetch("https://api.github.com/users/petekaik/repos")
      .then(response => {
        return response.json();
      })
      .then(data => {
        //console.log(data);
        this.setState(data);
      })
      .catch(() => {
        console.log(
          "No internet connection found. App is running in offline mode."
        );
      });
  }

  render() {
    if (this.state.length !== 0) {
      //return <p>{JSON.stringify(this.state)}</p>;
      const repos = Object.keys(this.state).map(key => this.state[key]);
      //console.log(repos);
      return repos.map(repo => <Repo key={repo.id} repo={repo} />);
      //return <p>Processing...</p>;
    } else {
      return (
        //https://loading.io/css/
        <div className="lds-ripple">
          <div />
          <div />
        </div>
      );
    }
  }
}

export default Repos;
