import React, { Component } from "react";

class Repo extends Component {
  state = {};
  render() {
    const { repo } = this.props;
    return (
      <section>
        <div className="repo">
          <p className="reponame">
            <a className="App-link" href={repo.html_url}>
              {repo.name}
            </a>
          </p>
          <p className="repodescription">{repo.description}</p>
        </div>
      </section>
    );
  }
}

export default Repo;
